<template>
<div>
    <!-- navbar -->
    <Navbar/>
    <div id="layoutSidenav">
        <Menu />
        <div id="layoutSidenav_content">
            <main>
            <!-- header -->
                 <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div class="container-xl px-4">
                            <div class="page-header-content pt-4">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-auto mt-4">
                                        <h1 class="page-header-title">
                                            <div class="page-header-icon"><i class="fas fa-bullhorn"></i></div>
                                            Anuncios
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
            <!-- fin header -->
            <!-- body -->
                <div class="container-xl px-4 mt-n10">
                        <!-- Wizard card navigation-->
                        <div class="card">
                            <div class="card-header border-bottom">
                                <!-- Wizard navigation-->
                                <div class="nav nav-pills nav-justified flex-column flex-xl-row nav-wizard" id="cardTab" role="tablist">
                                    <!-- Wizard navigation item 1-->
                                    <CardButton title="Anuncios existentes en el sistema" active="active" subTitle="" id="ingresados" :numberCard=cantidadNotificaciones /> 
                                </div>
                            </div>
                            <div class="card-body mt-0">
                                <div class="tab-content" id="cardTabContent">
                                    <!-- Wizard item 1-->

                                    <div v-if="this.listaNotificaciones != null">
                                        <div style="text-align: center;" v-if="this.listaNotificaciones.length == 0">No hay anuncios.</div>
                                         <Notificacion v-for="(dato) in this.listaNotificaciones" :key="dato.id" active="active" :infoMensaje=dato />
                                     </div>

                                    <span style="color:#f4a100" v-if="this.listaNotificacionesError != ''">{{listaNotificacionesError}}</span>
                                 </div>
                            </div>
                        </div>
                    </div>
            <!-- fin body -->
            </main>
        </div>
    </div>

</div>
</template>

<style>

.card-body {
    margin-top: 30px;
}

</style>

<script>
import router from '@/router/index.js' // Vue router instance
import spinner from '../../components/spinner'
import Vue from 'vue'
import Navbar from '../../components/home/navbar/navbar'
import Menu from '../../components/home/menu'
import CardButton from '../../components/home/step/util/cardButton'
import Notificacion from '../../components/home/step/notificaciones/Notificacion'

export default {
    name: "Home",
    components: {
        spinner,
        Navbar,
        Menu,
        CardButton,
        Notificacion
    },
    mounted: function () {
        this.addMenu();
        this.findNotificaciones();
        this.$store.dispatch("notificaciones/getNotificacionMenu");
    },
    methods: {
        addMenu(){
            document.body.classList.remove("sidenav-toggled");
        },
        async findNotificaciones() {
            await this.$store.dispatch("notificaciones/getNotificacionesByRol")
        }
    },
    computed: {
        listaNotificaciones: {
            get() {
                return this.$store.state.notificaciones.listaNotificaciones;
            }
        },
        listaNotificacionesError: {
            get() {
                return this.$store.state.notificaciones.listaNotificacionesError;
            }
        },        
        cantidadNotificaciones: {
            get() {
                return this.$store.state.notificaciones.cantidadNotificaciones;
            }
        },
        
    },
};
</script>
