<template>
<div style="display: flex;flex-direction: row;justify-content: center;">
    <div class="card card-icon mb-4 cardWidth">
        <div class="row g-0">
            <div class="col-auto card-icon-aside bg-info headMessage" style="background-color:#ddb66a !important">
                <i style="color: white" class="fas fa-bullhorn"></i>
            </div>
            <div class="col">
                <div class="card-body m-0 p-2">
                    <div class="stTitulo p-1">
                        <h5 class="card-title" style="font-size: 1rem">{{infoMensaje.titulo}}</h5>
                        <h6 style="font-size: 0.75rem">{{formateDate(infoMensaje.fecha_alta)}}</h6>
                    </div>
                    <div class="text-center">
                        <button type="button" @click="openMsg(infoMensaje.id,infoMensaje.descripcion)" class="btn btn-primary lift lift-sm btn-alta" style="background-color: rgb(221, 182, 106) !important;border: none;letter-spacing:2px;font-size: 13px;padding-top:8px;padding-bottom:8px ">LEER</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.noLeido {
    font-weight: 1000;
}

.stTitulo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.headMessage svg {
    height: 25px !important;
}

.cardWidth {
    width: 70%
}

.card-icon .card-icon-aside{
    padding:1.5rem
}

@media (max-width: 600px) {

    .cardWidth {
        width: 100%
    }

    .stTitulo {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
    }

    .headMessage {
        width: 100% !important;
        height: 35px !important;
        padding: 0px !important;
    }

    .headMessage svg {
        height: 20px !important;
    }

}

/* .swal2-icon.swal2-info{
border: none;
} */
</style>

<script>
import UtilJS from '../../../../assets/js/util'
import Vue from 'vue'

export default {

    name: "Notificacion",
    mixins: [UtilJS],
    props: ['infoMensaje'],
    methods: {
        openMsg(id, msg) {
            this.updateNotificacion(id);
            Vue.swal({
                title: "Notificacion",
                text: msg,
                iconHtml: ' <i style="color:#ddb66a !important" class="fas fa-bullhorn"></i>',
                icon: "info",
                confirmButtonText: 'Cerrar',
                confirmButtonColor: '#ddb66a',
                content: 'swal2-content-mio'
            }).then(function () {
            })
        },
        updateNotificacion(id) {
            this.$store.dispatch("notificaciones/updateNotificacion", id)
        },
        cutMsg(msg) {
            return msg.substring(0, 25) + " ....";
        }
    }

}
</script>
